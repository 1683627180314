@import url(//db.onlinewebfonts.com/c/000be6a5acfcd4e4a425e9f1bb6c80f0?family=Google+Sans);

body {
  font-size: 14px !important;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
}

.img-logo {
  height: 50px;
}
a {
  color: #129eb5;
}
.bg-dark {
  background-color: #006979 !important;
}
